import { useEffect, useRef } from "react";
import { Button, Descriptions, Form, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { DiventryBlock } from '../../Templates/Layout';
import PasswordReset from "../../Templates/PasswordReset";
import { UtilsInputLang } from "../../Templates/Utils";

export function Information(props) {
    const { t } = useTranslation();
    const formRef = useRef();

    useEffect(() => {
        formRef.current.setFieldsValue(props.values);
    }, [props.values]);

    return (
        <>
            <Form
                ref={formRef}
                name="complex-form"
                onFinish={props.onUpdate}
            >
                <DiventryBlock bottom={true}>
                    <h2>{t('Information.informationTitle')}</h2>

                    <Descriptions bordered>
                        <Descriptions.Item label={t('Information.firstName')}>
                            <Form.Item name='firstName' noStyle>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('Information.lastName')}>
                            <Form.Item name='lastName' noStyle>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('Information.language')}>
                            <Form.Item name='lang' noStyle>
                                <UtilsInputLang style={{ width: '100%' }} />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('Information.phone')}>
                            <Form.Item name='phone' noStyle>
                                <Input />
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item label={t('Information.accountId')}>
                            <Form.Item name='id' noStyle>
                                <Input disabled bordered={false} />
                            </Form.Item>
                        </Descriptions.Item>
                    </Descriptions>
                </DiventryBlock>

                <DiventryBlock>
                    <Space>
                        <Button type="primary" htmlType="submit">{t('Information.save')}</Button>
                        <Button>{t('Information.reset')}</Button>
                        <PasswordReset kernel={props.kernel} />
                    </Space>
                </DiventryBlock>
            </Form>
        </>
    );
}
