export default async function init(kernel) {
    kernel.log("Loading Licenses")
    kernel.subscription = {
        expiration: false,
    }
    kernel.subscription.expirationCheck = async () => {
        const ret = await kernel.api.get('/user/subscriptions/check-expired')
        if (!ret.error) {
            kernel.subscription.expiration = ret.data?.hasExpired;
        }
        setTimeout(kernel.subscription.expirationCheck, 5 * 60 * 1000)
    }
    await kernel.subscription.expirationCheck()
}

