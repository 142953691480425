import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Table,
    Space,
    notification,
    Tag,
    Select
} from 'antd';
import { DiventryBlock } from '../../Templates/Layout';
import { SubscriptionsItems } from './SubscriptionItems';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export function Subscriptions({ kernel }) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(true);
    const [list, setList] = useState([]);
    const { id } = useParams();
    
    const columns = [
        {
            title: t('Subscriptions.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => (
                <div>{text}</div>
            )
        },
        {
            title: t('Subscriptions.totalLicenseUsed'),
            dataIndex: 'totalAssignedDevices',
            key: 'totalAssignedDevices',
            render: (text, record) => (
                <div>{text} / {record?.totalQuantity}</div>
            )
        },
        {
            title: t('Subscriptions.duration'),
            dataIndex: 'priceId',
            key: 'priceId',
            width: '30%',
            render: (_, record) => (
                <Select
                    placeholder={t('Subscriptions.selectPrice')}
                    onChange={(value) => {
                        var products = list;
                        products.find(product => product?.id == record.id).priceId = value;
                        setList(products);
                        updateButtonList(products);
                    }}
                >
                    {record.prices?.map(price => (
                        <Option key={price.id} value={price.id}>
                            {price?.name ?? (`${price.currency.toUpperCase()} ${price.unit_amount / 100}`)}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: t('Subscriptions.quantity'),
            dataIndex: 'quantityId',
            key: 'quantityId',
            render: (_, record) => (
                <Select
                    placeholder={t('Subscriptions.selectQuantity')}
                    onChange={(value) => {
                        var products = list;
                        products.find(product => product?.id == record.id).quantityId = value;
                        setList(products);
                        updateButtonList(products);
                    }}
                    style={{ width: 100 }}
                >
                    {[10, 25, 50, 100, 250, 500, 1000, 1500, 2000, 2500, 3000, 5000, 10000, 20000, 50000]?.map(quantity => (
                        <Option key={quantity} value={quantity}>
                            {quantity}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: t('Subscriptions.unitPrice'),
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (_, record) => {
                var products = list;
                const product = products.find(product => record.prices?.find(price => product?.priceId == price?.id));
                const price = record.prices?.find(price => product?.priceId == price?.id)
                return (price?.unit_amount) ? <div>{(`${price.currency.toUpperCase()} ${(price.unit_amount / 100)}`)}</div> : <div></div>
            }
        },
        {
            title: t('Subscriptions.totalPrice'),
            dataIndex: 'prixId',
            key: 'prixId',
            render: (_, record) => {
                var products = list;
                const product = products.find(product => record.prices?.find(price => product?.priceId == price?.id));
                const price = record.prices?.find(price => product?.priceId == price?.id)
                return (product?.quantityId && price?.unit_amount) ? <div>{(`${price.currency.toUpperCase()} ${(price.unit_amount / 100) * product?.quantityId}`)}</div> : <div></div>
            }
        },
        {
            title: t('Subscriptions.action'),
            dataIndex: 'actions',
            key: 'actions',
            align: "right",
            width: '80px',
        },
    ];

    useEffect(() => {
        async function fetchList() {
            const response = await kernel.api.get("/user/products/list");
            if (!response || response.error) {
                notification.error({
                    message: t('Subscriptions.errorFetchingProducts'),
                    description: response?.error || null
                });
                return;
            }

            setList(response.data?.filter(item => item.name && item.name.startsWith("D")));
            updateButtonList(response.data?.filter(item => item.name && item.name.startsWith("D")));
        }

        fetchList();
    }, [loaded]);

    const updateButtonList = (_list = list) => {
        setList(_list?.map((item) => ({
            ...item,
            actions: (
                <Space size="small" style={{ background: 'transparent' }}>
                    <Button
                        onClick={() => handleView(item.id, item.priceId, item.quantityId)}
                        disabled={!item.quantityId || !item.priceId}
                    >
                        {t('Subscriptions.buy')}
                    </Button>
                </Space>
            ),
        })));
    }

    const handleView = async (productId, priceId, quantityId) => {
        if (!priceId) {
            notification.warning({
                message: t('Subscriptions.selectPriceWarning'),
            });
            return;
        }
    
        if (!quantityId) {
            notification.warning({
                message: t('Subscriptions.selectQuantityWarning'),
            });
            return;
        }
    
        try {
            const response = await kernel.api.get(`/user/products/create-checkout-session?productId=${productId}&priceId=${priceId}&quantity=${quantityId}`);
            if (response.error) {
                notification.error({
                    message: t('Subscriptions.errorInitiatingCheckout'),
                    description: response.error,
                });
                return;
            }
    
            // Rediriger vers l'URL de session Stripe
            window.location.href = response.data.url;
        } catch (error) {
            notification.error({
                message: t('Subscriptions.error'),
                description: error.message,
            });
        }
    };    

    return (
        <>
            <DiventryBlock>
                <Table
                    size="small"
                    dataSource={list}
                    columns={columns}
                    tableLayout="fixed"
                />
            </DiventryBlock>

            <SubscriptionsItems kernel={kernel} priceId={id}></SubscriptionsItems>
        </>
    );
}
