import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd';
import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { DiventryBlock } from '../../../../Templates/Layout';
import { DiventryPermissions } from '../../../../Templates/Permissions';
import { useTranslation } from 'react-i18next';

export function Permissions({ kernel, forwardId }) {
    const { t } = useTranslation();

    const permissions = {
        admin: t('Permissions.admin'),
        read: t('Permissions.read'),
    };

    return (
        <DiventryBlock>
            <DiventryPermissions
                kernel={kernel}
                forwardId={forwardId}
                permissionKey="dohzelProfile"
                permissions={permissions}
            />
        </DiventryBlock>
    );
}
