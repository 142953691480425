import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons';

import {
    DiventrySide
} from '../../../../Templates/Layout';

import { General } from './General';
import { Advanced } from './Advanced';
import { Permissions } from './Permissions';
import { Security } from './Security';
import { Allowlist, Blocklist } from './ABList';
import { Connect } from './Connect';

// Import de i18n
import { useTranslation } from "react-i18next";

const pages = {
    'general': {
        key: 'general',
        title: "Settings.general", // Utilisation de clé i18n
        ref: General
    },
    'security': {
        key: 'security',
        title: "Settings.securityPolicies", // Utilisation de clé i18n
        ref: Security
    },
    'connect': {
        key: 'connect',
        title: "Settings.dohzelConnect", // Utilisation de clé i18n
        ref: Connect
    },
    'allowlist': {
        key: 'allowlist',
        title: "Settings.allowList", // Utilisation de clé i18n
        ref: Allowlist
    },
    'blocklist': {
        key: 'blocklist',
        title: "Settings.blockList", // Utilisation de clé i18n
        ref: Blocklist
    },
    'permissions': {
        key: 'permissions',
        title: "Settings.userManagement", // Utilisation de clé i18n
        ref: Permissions
    },
    'advanced': {
        key: 'advanced',
        title: "Settings.advanced", // Utilisation de clé i18n
        ref: Advanced
    },
};

export function Settings({ kernel, updateProfile }) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { t } = useTranslation(); // Hook i18n

    const [loaded, setLoaded] = useState(true);
    const [page, setPage] = useState(null);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (params.page1 !== "settings")
            return;

        if (!params.page2) {
            navigate(`/dohzel/profile/${params.id}/settings/general`);
        }
        else {
            const el = pages[params.page2];
            if (!el)
                return (navigate(`/dohzel/profile/${params.id}/settings/general`));
            setPage(el);
            setContent(<el.ref kernel={kernel} updateProfile={updateProfile} forwardId={params.id} />);
        }
    }, [params]);

    return (
        <DiventrySide
            card={true}
            selectedKeys={[params.page2]}
            menu={Object.values(pages).map((item) => {
                return ({
                    key: item.key,
                    label: <Link>{t(item.title)}</Link>, // Utilisation de la clé i18n
                });
            })}
            onClick={(e) => {
                navigate(`/dohzel/profile/${params.id}/settings/${e.key}`);
            }}
        >
            {content}
        </DiventrySide>
    );
}
