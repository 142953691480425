// Updated Advanced.js with i18n integration
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import {
    Button,
    Space,
    Popconfirm,
    Form,
    message,
    Menu,
    Divider,
    Dropdown,
    notification
} from 'antd';

import { DiventryBlock } from '../../../../Templates/Layout';
import { DownOutlined } from '@ant-design/icons';

export function Advanced({ kernel, updateDevice }) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(true);

    // main document
    const [info, setInfo] = useState({});
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState('');

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    // async load
    useEffect(() => {
        async function fetch() {
            const raw = await kernel.api.get(`/dohzel/device/get?id=${id}`);
            if (raw.error || !raw.data) return navigate("/dohzel/profile");
            setSelectedProfile(raw.data?.profileId);
            setInfo(raw.data);
        }
        fetch();
    }, [id, loaded]);

    useEffect(() => {
        (async () => {
            var response = await kernel.api.get("/dohzel/profile/me");
            if (response.error) {
                return;
            }
            setProfiles(response.data.list);
        })();
    }, [id, loaded]);

    const confirm = async (e) => {
        const deletedDevice = await kernel.api.post(`/dohzel/profile/exclude`, {
            id: info.profileId,
            deviceId: info.id
        });
        if (deletedDevice.error) return;
        message.success(t('AdvancedDevice.excludeSuccess'));
        navigate("/dohzel/profile");
    };

    const handleSizeClick = async (e) => {
        const selectedLabel = e.key;
        setSelectedProfile(selectedLabel);
        const ret = await kernel.api.post(`/dohzel/device/update`, {
            ...info,
            profileId: selectedLabel
        });
        if (!ret) {
            notification.error({
                message: t('AdvancedDevice.serviceUnavailable')
            });
            return;
        }
        if (ret.error) {
            notification.error({
                message: ret.error
            });
            return;
        }

        notification.success({
            message: t('AdvancedDevice.deviceMoved')
        });
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <DiventryBlock>
                <Divider>{t('AdvancedDevice.excludeProfile')}</Divider>
                <Space.Compact>
                    <Popconfirm
                        title={t('AdvancedDevice.popconfirmTitle')}
                        description={t('AdvancedDevice.popconfirmDescription')}
                        onConfirm={confirm}
                        okText={t('AdvancedDevice.popconfirmOkText')}
                        cancelText={t('AdvancedDevice.popconfirmCancelText')}
                    >
                        <Button danger type="primary">{t('AdvancedDevice.excludeDevice')}</Button>
                    </Popconfirm>
                </Space.Compact>

                {info?.deviceManagement === 'mdm' && (
                    <>
                        <Divider>{t('AdvancedDevice.changeProfile')}</Divider>
                        <Dropdown
                            overlay={(
                                <Menu onClick={handleSizeClick}>
                                    {profiles.map(profile => (
                                        <Menu.Item key={profile?.id}>{profile?.name}</Menu.Item>
                                    ))}
                                </Menu>
                            )}
                        >
                            <Button>
                                <Space>
                                    {profiles?.find(profile => profile?.id === selectedProfile)?.name ?? ''}
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </>
                )}
            </DiventryBlock>
        </div>
    );
}