import { useEffect, useState } from 'react';
import {
    Table,
    Button,
    Tag,
    Space,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification
} from 'antd';
import { SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export function DiventryPermissions({
    kernel,
    forwardId,
    permissionKey,
    permissions
}) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [modalEdit, setModalEdit] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        async function fetch() {
            const raw = await kernel.api.get(`/user/permissions/list?type=${permissionKey}&forwardId=${forwardId}`);
            if (raw.error || !raw.data) {
                notification.error({
                    message: t('DiventryPermissions.fetchError'),
                    description: raw.error
                });
                return;
            }
            setTable(raw.data);
        }
        fetch();
    }, [loaded, forwardId]);

    const handleOk = async () => {
        const data = form.getFieldsValue();
        data.type = permissionKey;

        let endpoint = `/user/permissions/create`;
        if (modalEdit) {
            delete data.userId;
            delete data.forwardId;
            endpoint = `/user/permissions/update`;
        } else {
            data.forwardId = forwardId;
        }

        const ret = await kernel.api.post(endpoint, data);
        if (!ret || ret.error) {
            notification.error({
                message: modalEdit
                    ? t('DiventryPermissions.updateError')
                    : t('DiventryPermissions.addError'),
                description: ret.error
            });
            return;
        }
        notification.success({
            message: modalEdit
                ? t('DiventryPermissions.updateSuccess')
                : t('DiventryPermissions.addSuccess')
        });

        setLoaded(!loaded);
        setIsModal(false);
    };

    const handleCancel = () => {
        setIsModal(false);
    };

    const columns = [
        {
            title: t('DiventryPermissions.tableUser'),
            dataIndex: 'userId',
            key: 'userId',
            render: (text) => <strong>{text}</strong>
        },
        {
            title: t('DiventryPermissions.tableCreated'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => new Date(text).toLocaleString()
        },
        {
            title: t('DiventryPermissions.tableAccess'),
            dataIndex: permissionKey,
            key: permissionKey,
            render: (text, record) =>
                Array.isArray(record[permissionKey])
                    ? record[permissionKey].map((item) => (
                          <Tag key={`access-${record.forwardId}-${item}`}>{permissions[item]}</Tag>
                      ))
                    : ""
        },
        {
            dataIndex: 'id',
            align: "right",
            render: (text, record) => (
                <Space size="small">
                    <Popconfirm
                        placement="left"
                        title={t('DiventryPermissions.deleteConfirmation')}
                        okText={t('DiventryPermissions.yes')}
                        cancelText={t('DiventryPermissions.no')}
                        onConfirm={async () => {
                            const ret = await kernel.api.post(`/user/permissions/remove`, { id: record.id, type: permissionKey });
                            if (ret.error) {
                                notification.error({
                                    message: t('DiventryPermissions.deleteError'),
                                    description: ret.error
                                });
                                return;
                            }

                            notification.success({
                                message: t('DiventryPermissions.deleteSuccess')
                            });
                            setLoaded(!loaded);
                        }}
                    >
                        <Button><DeleteOutlined /></Button>
                    </Popconfirm>
                    <Button
                        onClick={() => {
                            setModalEdit(true);
                            form.resetFields();
                            form.setFieldsValue(record);
                            setIsModal(true);
                        }}
                    >
                        <SettingOutlined />
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <>
            <Modal
                title={modalEdit ? t('DiventryPermissions.updateModal') : t('DiventryPermissions.addModal')}
                open={isModal}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
                <Form form={form}>
                    {modalEdit && (
                        <Form.Item label={t('DiventryPermissions.ruleId')} name="id" hidden>
                            <Input disabled />
                        </Form.Item>
                    )}
                    <Form.Item
                        label={modalEdit ? t('DiventryPermissions.user') : t('DiventryPermissions.userShareCode')}
                        name="userId"
                    >
                        <Input disabled={modalEdit} />
                    </Form.Item>
                    <Form.Item label={t('DiventryPermissions.userRoles')} name={permissionKey}>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder={t('DiventryPermissions.selectPlaceholder')}
                            options={Object.keys(permissions).map((item) => ({ value: item, label: permissions[item] }))}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <p>
                <Button
                    type="primary"
                    onClick={() => {
                        setModalEdit(false);
                        form.resetFields();
                        setIsModal(true);
                    }}
                >
                    {t('DiventryPermissions.addUser')}
                </Button>
            </p>
            <Table pagination={false} dataSource={table} columns={columns} />
        </>
    );
}
