import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Space,
  Input,
  Button,
  Tag,
  Spin,
  Switch,
  Popconfirm,
  notification
} from "antd";
import {
  CompassOutlined,
  GlobalOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { DiventryBlock } from "../../../Templates/Layout";
import { LogTable } from "../../../Templates/Logs";
import { useTranslation } from "react-i18next";

export function ABList({ kernel, list = "allow", color = "black", type }) {
  const { t } = useTranslation();
  const params = useParams();

  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false);

  const [input, setInput] = useState(null);
  const [rules, setRules] = useState([]);
  const [oppositRules, setOppositRules] = useState([]);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmActiveVisible, setConfirmActiveVisible] = useState(false);

  useEffect(() => {
    async function fetchRules() {
      setLoading(true);
      const ret = await kernel.api.get(
        `/dohzel/ablist/list?${type}=${params.id}&list=${list}`
      );
      setLoading(false);
      if (!ret || ret.error) {
        notification.error({
          message: t("ABList.errorAddingRule"),
          description: ret.error
        });
        return;
      }
      setRules(ret.data);
    }
    fetchRules();
  }, [params.id, loaded]);

  useEffect(() => {
    async function fetchOppositeRules() {
      setLoading(true);
      const ret = await kernel.api.get(
        `/dohzel/ablist/list?${type}=${params.id}&list=${
          list === "allow" ? "block" : "allow"
        }`
      );
      setLoading(false);
      setOppositRules(ret.data);
    }
    fetchOppositeRules();
  }, [list, params.id, loaded]);

  async function addRule() {
    const data = {
      list: list
    };
    data[type] = params.id;

    const masked = input.split("/");
    let detection = "Domain";
    if (/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/.test(masked[0])) {
      detection = "IPv4";
      data.type = "cidr";
      data.cidr = input;
    } else if (masked[0].indexOf(":") > 0) {
      detection = "IPv6";
      data.type = "cidr";
      data.cidr = input;
    } else {
      data.type = "domain";
      const t = input.split(".");
      if (t[0] === "*") {
        data.domainWildcard = true;
        t.shift();
        data.domain = t.join(".");
      } else {
        data.domain = input;
      }
    }

    const ret = await kernel.api.post(`/dohzel/ablist/create`, data);
    if (!ret || ret.error) {
      notification.error({
        message: t("ABList.errorAddingRule"),
        description: ret.error
      });
      return;
    }

    setInput("");
    setLoaded(!loaded);
  }

  const disableInOppositeList = async () => {
    const item = oppositRules?.find(
      (domain) => domain.cidr === input || domain.domain === input
    );
    const data = {
      id: item.id,
      activate: false
    };
    setLoading(true);
    const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
    setLoading(false);

    setLoaded(!loaded);
  };

  const changeActivationStatus = async (item) => {
    const data = {
      id: item.id,
      activate: !item.activate
    };
    setLoading(true);
    const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
    setLoading(false);
    if (!ret || ret.error) {
      notification.error({
        message: t("ABList.errorChangingMode"),
        description: ret.error
      });
      return;
    }

    setLoaded(!loaded);
  };

  return (
    <DiventryBlock>
      <div style={{ display: "flex", gap: "10px", paddingBottom: 10 }}>
        <Input
          placeholder={t("ABList.addDomainPlaceholder")}
          onChange={(el) => setInput(el.target.value)}
          value={input}
          allowClear={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (
                oppositRules?.find(
                  (domain) =>
                    domain.cidr === input || domain.domain === input
                )?.activate
              ) {
                setConfirmVisible(true);
              } else {
                addRule();
              }
              return;
            }
          }}
        />
        {!oppositRules?.find(
          (domain) => domain.cidr === input || domain.domain === input
        )?.activate && <Button onClick={addRule}>{t("ABList.addButton")}</Button>}
        {oppositRules?.find(
          (domain) => domain.cidr === input || domain.domain === input
        )?.activate && (
          <Popconfirm
            placement="left"
            title={t("ABList.domainConflictMessage")}
            description={t("ABList.domainConflictDescription", {
              listType: list === "allow" ? t("block") : t("allow")
            })}
            okText={t("ABList.continueButton")}
            visible={confirmVisible}
            onClick={() => setConfirmVisible(true)}
            cancelText={t("ABList.cancelButton")}
            onCancel={() => setConfirmVisible(false)}
            onConfirm={async () => {
              addRule();
              disableInOppositeList();
              setConfirmVisible(false);
            }}
          >
            <Button>{t("ABList.addButton")}</Button>
          </Popconfirm>
        )}
      </div>
      <Spin size="large" spinning={loading}>
        <LogTable
          kernel={kernel}
          data={rules.map((item) => ({
            color: color,
            left: (
              <div style={{ fontWeight: 600, fontSize: 17 }}>
                {item.type === "cidr" ? (
                  item.cidr
                ) : (
                  <>
                    {item.domainWildcard === true && (
                      <span style={{ opacity: 0.5 }}>*.</span>
                    )}
                    {item.domain}
                  </>
                )}
              </div>
            ),
            right: (
              <Space>
                {item.type === "cidr" && (
                  <Tag color="geekblue" bordered={false} style={{ fontSize: 10, fontWeight: 700 }}>
                    <GlobalOutlined /> {t("ABList.ipTag")}
                  </Tag>
                )}
                {item.type === "domain" && (
                  <Tag color="purple" bordered={false} style={{ fontSize: 10, fontWeight: 700 }}>
                    <CompassOutlined /> {t("ABList.domainTag")}
                  </Tag>
                )}
                <Switch
                  size="small"
                  checked={item.activate}
                  onChange={() => changeActivationStatus(item)}
                />
                <Button
                  size="small"
                  onClick={async () => {
                    const data = { id: item.id };

                    setLoading(true);
                    const ret = await kernel.api.post(
                      `/dohzel/ablist/remove`,
                      data
                    );
                    setLoading(false);
                    if (!ret || ret.error) {
                      notification.error({
                        message: t("ABList.errorDeletingEntry"),
                        description: ret.error
                      });
                      return;
                    }

                    setLoaded(!loaded);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            )
          }))}
        />
      </Spin>
    </DiventryBlock>
  );
}
