import { useRef, useEffect, useState } from 'react';
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import logoBig from '../Templates/logo.png'
import anime from 'animejs'
import { useTranslation } from 'react-i18next'; 

const CSScenter = {
    fontFamily: "Montserrat",
    position: "fixed",
    textAlign: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}

const CSSlogo = {
    color: "rgb(126,176,34)",
    fontFamily: "Montserrat",
    fontWeight: 900,
}

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
            color: "#181818"
        }}
        spin
    />
);

const speed = 250;

export function Splash({ children, onFinish }) {
    const [loaded, setLoaded] = useState(true)

    const animationRef = useRef(null);
    
    const { t } = useTranslation();
    
    useEffect(() => {

        anime({
            targets: ['#message'],
            easing: 'easeInOutElastic',
            duration: 1000,
            opacity: [0, 1],
            // loop: true,
            // delay: anime.stagger(1000),
            complete: () => {
                //   window.location.href = article.link
            }
        })

        const timeline = anime.timeline({
            autoplay: true,
            // easing: 'easeInOutElastic',
            duration: 250,
            easing: 'easeOutElastic(0, 1)',
            complete: onFinish
        })

        timeline.add({
            targets: '#logo',
            opacity: [0, 1],
            // duration: 1000,
            // delay: anime.stagger(4000)
        })

        timeline.add({
            targets: '#title',
            opacity: [0, 1],
            translateX: [~window.innerWidth, 0],
            // delay: anime.stagger(speed)
        })

        timeline.add({
            targets: '#subtitle',
            opacity: [0, 1],
            translateX: [window.innerWidth, 0],
            // delay: anime.stagger(speed)
        })

    }, [loaded])

    return (
        <>
            <div style={CSScenter}>
                <div style={{ minWidth: 350 }}>
                    <div style={{}}>
                        <div id="logo"><img src={logoBig} width={150} /></div>
                        <div id="title" style={{ ...CSSlogo, fontSize: 30 }}>HAFNOVA</div>
                        <div id="subtitle" style={{
                            color: "#181818",
                            fontSize: "0.7em",
                            
                            // textTransform: "uppercase"
                        }}>
                            {t('Splash.introduction')}
                        </div>
                    </div>

                    <Spin indicator={antIcon} style={{ paddingTop: 40 }} />

                    <div id="message" style={{
                        width: "100%",
                        height: 60,
                        paddingTop: 10,
                        textAlign: "center",
                        fontSize: "0.7em",
                        color: "#181818",
                    }}>
                        <br />
                        {children}
                    </div>
                </div>
            </div>

            <div style={{
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "100%",
                // backgroundColor: "red",
                textAlign: "center"
            }}>
                <div style={{
                    color: "#181818",
                    fontWeight: 500,
                    fontSize: 10,
                    textTransform: "uppercase"
                }}>
                    Hafnova 2024
                </div>
            </div>
        </>

    );
}