import { useCallback } from "react"

import {
    Table,
    theme
} from 'antd';

import {
    SearchOutlined,
    LeftOutlined,
    SyncOutlined,
    RightOutlined
} from '@ant-design/icons'

import {
    LogsColors
} from '../../../Plans/Templates/LogsStream'
import { ABList } from "../../../Plans/Templates/ABList";
import { useTranslation } from "react-i18next";

export function Allowlist({ kernel }) {
    const { t } = useTranslation();

    return (
        <ABList 
            kernel={kernel} 
            list="allow" 
            color={LogsColors.passed} 
            type="profileId" 
            title={t('Allowlist.title')} // Traduction ajoutée
        />
    )
}

export function Blocklist({ kernel }) {
    const { t } = useTranslation();

    return (
        <ABList 
            kernel={kernel} 
            list="block" 
            color={LogsColors.blocking} 
            type="profileId" 
            title={t('Blocklist.title')} // Traduction ajoutée
        />
    )
}
