
import { useEffect, useState } from 'react'
import { Content } from '../Templates'



export function Info({ kernel }) {
    return (
        <Content>
            <h1>System Information</h1>
            <pre>
                {JSON.stringify(kernel.device, null, " ") }
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>                
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
                vfsvfds<br/>
            </pre>
        </Content>
    );
}