import { Menu } from 'antd';
import { useEffect, useState } from "react";
import {
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";

import logoColors from './logo-favicon.png';

// import { LibAPIget, LibAPIisImpersonate, LibAPIexit } from "../../lib/LibAPI"

import {
    AimOutlined,
    VideoCameraAddOutlined,
    HeatMapOutlined,
    AuditOutlined,
    BugOutlined
} from '@ant-design/icons'

import MenuDropDown from './MenuDropDown'

export function DiventryMenu(props) {
    const [loaded, setLoaded] = useState(true)
    const [current, setCurrent] = useState([]);
    const [impersonate, setImpersonate] = useState(false)
    const [menu, setMenu] = useState([])
    const [data, setData] = useState(null)

    const navigate = useNavigate();
    const location = useLocation();

    const items = [
        // {
        //     label: 'Dashboard',
        //     key: 'dashboard',
        //     // icon: <MailOutlined />,
        // },
        {
            label: (
                <Link rel="noopener noreferrer">
                    DoHzel
                </Link>
            ),
            key: 'projects',
            icon: <VideoCameraAddOutlined />,
            key: 'dohzel.profile',
            // children: [
            //     {
            //         label: 'Console Management',
            //         key: 'dohzel.profile',
            //         // icon: <AppstoreOutlined />,
            //     },
            //     // {
            //     //     label: 'All profiles',
            //     //     key: 'dohzel.profiles',
            //     //     // icon: <AppstoreOutlined />,
            //     // },
    
            // ],
            __filter: (me) => {
                return (me != null)
            }
        },
        // {
        //     label: 'CyberIntel',
        //     key: 'alerts',
        //     icon: <AlertOutlined />,
        //     __filter: (me) => {
        //         return (me != null)
        //     }
        // },
        {
            label: 'ThreatDB',
            key: 'threatdb',
            disabled: props.kernel.subscription.expiration,
            icon: <AimOutlined />,
            children: [
                {
                    label: <Link rel="noopener noreferrer">
                        List management
                    </Link>,
                    key: 'threatdb.list',
                    // icon: <AppstoreOutlined />,
                },
                {
                    label: <Link rel="noopener noreferrer">
                        Global Master IOC
                    </Link>,
                    key: 'threatdb.master',
                    // icon: <AppstoreOutlined />,
                    children: [
                        {
                            label: <Link rel="noopener noreferrer">
                                Domain Names
                            </Link>,
                            key: 'threatdb.global.domains.data',
                        },
                        {
                            label: <Link rel="noopener noreferrer">
                                IPv4 addresses
                            </Link>,
                            key: 'threatdb.global.ipv4.data',
                        },
                        {
                            label: <Link rel="noopener noreferrer">
                                IPv6 addresses
                            </Link>,
                            key: 'threatdb.global.ipv6.data',
                        },
                    ]
                },
                {
                    label: <Link rel="noopener noreferrer">
                        Tags Management
                    </Link>,
                    key: 'threatdb.tags',
                    // icon: <AppstoreOutlined />,
                    __filter: (me) => {
                        return (me && me.admin === true)
                    }
                },
                {
                    label: <Link rel="noopener noreferrer">
                        Search for IoC
                    </Link>,
                    key: 'threatdb.search-ioc',
                    // icon: <AppstoreOutlined />,
                },
    
            ],
            __filter: (me) => {
                return (true)
            }
        },
    
    
        {
            label: 'Admin',
            key: 'admin',
            icon: <HeatMapOutlined />,
            __filter: (me) => {
                return (me && me.admin === true)
            },
            children: [
                {
                    label: <Link rel="noopener noreferrer">
                        DoHzel Review
                    </Link>,
                    key: 'admin.dohzel-review',
                    icon: <BugOutlined />,
                    __filter: (me) => {
                        return (me && me.admin === true)
                    }
                },
                {
                    label: <Link rel="noopener noreferrer">
                        Tags Management
                    </Link>,
                    key: 'admin.tags',
                    icon: <AuditOutlined />,
                    __filter: (me) => {
                        return (me && me.admin === true)
                    }
                },
                {
                    label: <Link rel="noopener noreferrer">
                        DoHzel Offer
                    </Link>,
                    key: 'admin.dohzel-offer',
                    icon: <BugOutlined />,
                    __filter: (me) => {
                        return (me && me.admin === true)
                    }
                },
            ]
        }
    ]

    useEffect(() => {
        // choose the good selected menu
        const pathCurrent = []
        const st = location.pathname.split("/")
        st.shift()
        while (st.length > 0) {
            pathCurrent.push(st.join("."))
            st.pop()
        }
        setCurrent(pathCurrent)


        var interval = null

        const loader = async () => {
            // setImpersonate(await LibAPIisImpersonate())

            var response = await props.kernel.api.get("/user/me")
            if (response.error) {
                interval = setTimeout(loader, 10000);
                return
            }

            function filtering(src) {
                return (src.filter((item) => {
                    if (item.__filter) return (item.__filter(response.data))
                    return (true)
                }).map((item) => {
                    if (item.children)
                        item.children = filtering(item.children)
                    delete item.__filter
                    return (item)
                }))
            }
            setMenu(filtering(items))

            setData(response.data)
        }
        loader()

        return () => {
            if (interval)
                clearTimeout(interval)
        }
        // setMenu(items)
    }, [loaded])

    const leaveImpersonate = async () => {
        // await LibAPIexit()
        // navigate("/")
        // document.location.reload()
    }


    const onClick = (item) => {
        const path = item.key.split(".").join("/")
        navigate(`/${path}`)
        // setCurrent(item.key)
    }

    return (<>
        <div style={{
            textAlign: "center",
            paddingTop: 10,
            height: 90,
            background: "rgba(229,228,229,0.5)",
            // borderBottom: "2px groove RGBA(208,255,0,0.5)"
        }}>
            <div style={{
                // minWidth: 450,
                maxWidth: 1440,
                margin: "0 auto"
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    <Link to="/dohzel/profile">
                        <img width={64} src={logoColors} style={{
                            marginLeft: 20,
                            marginRight: 20
                        }} />
                    </Link>
                    <Menu
                        style={{
                            width: "50%",
                            background: "rgba(125,125,125,0)",
                        }}
                        onClick={onClick}
                        selectedKeys={current}
                        mode="horizontal"
                        items={menu} />
                    {data && <MenuDropDown user={data} kernel={props.kernel} navigate={navigate} />}

                </div>
            </div>
        </div>
    </>);
}
