import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import {
    Button,
    Tabs,
    Alert,
    Tag,
    Space,
    Popconfirm,
    Card,
    Table,
    Modal,
    Form,
    Input,
    Select,
    Empty,
    Col,
    Row,
    notification
} from 'antd'
import {
    CompassOutlined,
    EditOutlined, DeleteOutlined
} from '@ant-design/icons';
import { DiventryBlock } from '../../../../Templates/Layout'
import Brands from '../../Brands/Index'
import { useTranslation } from "react-i18next";

/*
 *
 * ConnectRules
 */

export function ConnectRulesTest({ kernel }) {
    const { t } = useTranslation();

    const [form] = Form.useForm()

    const [isModal, setModal] = useState(false)
    const [modalDescription, setModalDescription] = useState(null)
    const [modalResults, setModalResults] = useState(null)

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    async function openModal() {
        setModalResults(null)
        setModalDescription(t("ConnectRulesTest.DescriptionPleaseType"))
        form.resetFields()
        setModal(true)
    }

    async function onOk() {
        setModalResults(null)
        setModalDescription(t("ConnectRulesTest.DescriptionLoading"))
        const values = form.getFieldsValue()

        const data = {
            query: values.query
        }

        const raw = await kernel.api.post(`/dohzel/connect/test?profileId=${id}`, data)
        if (raw.error || !raw.data) {
            if (!raw.error && !raw.error)
                setModalDescription(t("ConnectRulesTest.DescriptionError") + ": No data")
            else
                setModalDescription(t("ConnectRulesTest.DescriptionError") + ": " + raw.error)
            return
        }

        setTimeout(() => {
            const results = { ...raw.data }
            if (results.length === 0)
                return (setModalDescription(t("ConnectRulesTest.DescriptionError") + ": No record"))
            setModalResults(results)
            setModalDescription(null)
        }, 500)
    }

    async function onCancel() {
        setModal(false)
    }

    return (<>
        <Modal
            title={t("ConnectRulesTest.Title")}
            open={isModal}
            onOk={onOk}
            onCancel={onCancel}
            okText={t("ConnectRulesTest.Tester")}
            cancelText={t("ConnectRulesTest.Close")}
            // okButtonProps={{disabled: true}}
            forceRender
        >
            <Alert
                type="info" showIcon
                description={t("ConnectRulesTest.info")}
                closable
            />
            <p style={{ paddingTop: 10 }}>
                <Form
                    form={form}
                >
                    <Form.Item label={t("ConnectRulesTest.Domain")} name="query">
                        <Input />
                    </Form.Item>
                    {modalResults ?
                        <Form.Item label={t("ConnectRulesTest.Route")} disabled={true}>
                            {modalResults.selector ? <>
                                {modalResults.selector.type}={modalResults.selector.domain ? modalResults.selector.domain : modalResults.selector.regex}
                            </> : "Default output"}
                        </Form.Item>
                        : null}
                </Form>
            </p>

            {modalResults ?
                <p style={{ paddingTop: 10 }}>
                    <Row>
                        {modalResults.replies.map((item) => {
                            return (<Col span={12}><Tag>{item.type}</Tag> {item.data}</Col>)
                        })}
                    </Row>
                </p>
                : null}

            {modalDescription ? <Empty description={modalDescription} /> : null}
        </Modal>

        <Button
            onClick={() => {
                openModal()
            }}
        >
            {t("ConnectRulesTest.Button")}
        </Button>
    </>)
}

export function ConnectRules({ kernel }) {
    const { t } = useTranslation();
    const [form] = Form.useForm()

    const [loaded, setLoaded] = useState(true)
    const [isModal, setModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState({})
    const [devices, setDevices] = useState([])
    const [devicesLoading, setDevicesLoading] = useState(true)

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    // async load
    useEffect(() => {
        // fetch devices
        setDevicesLoading(true)
        async function fetchDevices() {
            const raw = await kernel.api.get(`/dohzel/connect/devices?profileId=${id}`)
            if (raw.error || !raw.data) {
                setTimeout(fetchDevices, 2000)
                return
            }
            setDevices(raw.data)
            setTimeout(() => setDevicesLoading(false), 500)
        }
        fetchDevices();
    }, [id, isModal]);

    // load data source
    const [rules, setRules] = useState([])
    const [rulesLoading, setRulesLoading] = useState(true)
    useEffect(() => {
        setRulesLoading(true)
        async function fetchRules() {
            const raw = await kernel.api.get(`/dohzel/connect/list?profileId=${id}`)
            if (raw.error || !raw.data) {
                setTimeout(fetchRules, 2000)
                return
            }
            setRules(raw.data)
            setTimeout(() => setRulesLoading(false), 500)
        }
        fetchRules();
    }, [id, loaded]);

    useEffect(() => {
        form.setFieldsValue(data)
    }, [id, data])

    async function onOk() {
        const values = form.getFieldsValue()
        values.profileId = id

        if (update)
            values.id = update

        const ret = update ?
            await kernel.api.post(`/dohzel/connect/update`, values) :
            await kernel.api.post(`/dohzel/connect/create`, values)

        if (ret.error) {
            notification.error({
                message: update ? t("ConnectRules.updateError") : t("ConnectRules.createError"),
                description: ret.error
            })
            return
        }
        notification.success({
            message: update ? t("ConnectRules.updated") : t("ConnectRules.created")
        })
        setLoaded(!loaded)
        setModal(false)
    }

    async function onCancel() {
        setModal(false)
    }

    return (<>
        <Modal
            title={update ? t("ConnectRules.updateTitle") : t("ConnectRules.createTitle")}
            open={isModal}
            onOk={onOk}
            onCancel={onCancel}
            forceRender
        >
            <Form
                form={form}
            >
                <Form.Item label={t("ConnectRules.typeLabel")} name="type">
                    <Select
                        style={{ width: '100%' }}
                        options={[
                            {
                                value: "domain",
                                label: t("ConnectRules.domainRule")
                            },
                            {
                                value: "regex",
                                label: t("ConnectRules.regexRule")
                            }
                        ]}
                        disabled={update}
                        onChange={(id) => {
                            setData({ ...data, type: id })
                        }}
                    />
                </Form.Item>

                {data.type === "domain" ? <>
                    <Form.Item label={t("ConnectRules.domainNameLabel")} name="domain">
                        <Input />
                    </Form.Item>
                </> : null}

                {data.type === "regex" ? <>
                    <Form.Item label={t("ConnectRules.regexLabel")} name="regex">
                        <Input />
                    </Form.Item>
                </> : null}

                <Form.Item label={t("ConnectRules.forwardedDeviceLabel")} name="forwardedDeviceId">
                    <Select
                        placeholder={devicesLoading === true ? t("ConnectRules.loadingText") : t("ConnectRules.selectDevice")}
                        style={{ width: '100%' }}
                        options={devices.map((item) => {
                            return ({
                                value: item.id,
                                label: <>
                                    <strong>{item.name}</strong>
                                    {item.connectCap === true ?
                                        <Tag color="green" bordered={false}>{t("ConnectRules.connected")}</Tag> :
                                        <Tag color="orange" bordered={false}>{t("ConnectRules.disconnected")}</Tag>
                                    }
                                </>
                            })
                        })}
                        loading={devicesLoading} />
                </Form.Item>
            </Form>
        </Modal>

        <div style={{ paddingTop: 5 }}>
            <Space.Compact>
                <Button
                    type="primary"
                    onClick={() => {
                        setLoaded(!loaded)
                        setData({
                            type: "domain",
                            regex: "",
                            domain: "",
                            forwardedDeviceId: ""
                        })
                        setUpdate(null)
                        setModal(true)
                    }}
                >
                    {t("ConnectRules.addRuleButton")}
                </Button>

                <ConnectRulesTest kernel={kernel} />
            </Space.Compact>

            <Table
                dataSource={rules}
                columns={[
                    {
                        title: t("ConnectRules.typeColumn"),
                        dataIndex: 'type',
                        key: 'type',
                    },
                    {
                        title: t("ConnectRules.ruleColumn"),
                        dataIndex: 'rule',
                        key: 'rule',
                        render: (text, record, index) => {
                            if (record.type === "domain")
                                return (<Tag color="#108ee9">{record.domain}</Tag>)
                            else if (record.type === "regex")
                                return (<Tag color="#87d068">{record.regex}</Tag>)
                            return ("-")
                        }
                    },
                    {
                        title: t("ConnectRules.forwardedDeviceColumn"),
                        dataIndex: 'forwardedDeviceInfo',
                        key: 'forwardedDeviceInfo',
                        render: (text, record, index) => {
                            return (<Brands.BrandedDevice connectStatus={true} device={record.forwardedDevice} />)
                        }
                    },
                    {
                        title: t("ConnectRules.actionsColumn"),
                        dataIndex: 'actions',
                        key: 'actions',
                        align: "right",
                        render: (text, record, index) => {
                            return (<Space.Compact size="small">
                                <Button type="default" onClick={() => {
                                    setLoaded(!loaded)
                                    setData({
                                        type: record.type,
                                        regex: record.regex,
                                        domain: record.domain,
                                        forwardedDeviceId: record.forwardedDeviceId
                                    })
                                    setUpdate(record.id)
                                    setModal(true)
                                }}><EditOutlined /></Button>

                                <Popconfirm
                                    title={t("ConnectRules.deleteConfirmTitle")}
                                    onCancel={() => { }}
                                    onConfirm={async () => {
                                        const ret = await kernel.api.post(`/dohzel/connect/remove`, {
                                            profileId: id,
                                            id: record.id
                                        })
                                        if (ret.error) {
                                            notification.error({
                                                message: t("ConnectRules.deleteError"),
                                                description: ret.error
                                            })
                                            return
                                        }
                                        notification.success({
                                            message: t("ConnectRules.deleted")
                                        })
                                        setLoaded(!loaded)
                                    }}
                                >
                                    <Button type="default" ><DeleteOutlined /></Button>
                                </Popconfirm>
                            </Space.Compact>)
                        }
                    },
                ]}
            />
        </div>
    </>)
}

/*
 *
 * ConnectDevices
 */
export function ConnectDevices({ kernel }) {
    const { t } = useTranslation();
    const [form] = Form.useForm()

    const [loaded, setLoaded] = useState(true)
    const [isModal, setModal] = useState(false)
    const [modalDevice, setModalDevice] = useState(null)
    const [modalDescription, setModalDescription] = useState(null)
    const [modalResults, setModalResults] = useState(null)
    const [data, setData] = useState({})
    const [devices, setDevices] = useState([])
    const [devicesLoading, setDevicesLoading] = useState(true)

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    // async load
    useEffect(() => {
        // fetch devices
        setDevicesLoading(true)
        async function fetchDevices() {
            const raw = await kernel.api.get(`/dohzel/connect/devices?profileId=${id}`)
            if (raw.error || !raw.data) {
                setTimeout(fetchDevices, 2000)
                return
            }
            setDevices(raw.data)
            setTimeout(() => setDevicesLoading(false), 500)
        }
        fetchDevices();
    }, [id, loaded]);

    const columns = [
        {
            title: t("ConnectDevices.deviceColumn"),
            dataIndex: 'device',
            key: 'device',
            render: (text, record, index) => {
                return (<Brands.BrandedDevice connectStatus={true} device={record} />)
            }
        },
        {
            title: t("ConnectDevices.lastBeatColumn"),
            dataIndex: 'beatAt',
            key: 'beatAt',
            align: "right",
            render: (text, record, index) => {
                return (new Date(record.beatAt).toLocaleString())
            }
        },
        {
            title: "",
            dataIndex: 'beatAt',
            key: 'beatAt',
            align: "right",
            render: (text, record, index) => {
                return (
                    <Button size="small" onClick={() => openModal(record.id)}>
                        {t("ConnectDevices.TestDNS.Button")}
                    </Button>
                )
            }
        },
    ];

    async function openModal(deviceId) {
        setModalResults(null)
        setModalDevice(deviceId)
        setModalDescription(t("ConnectDevices.TestDNS.Description.PleaseType"))
        form.resetFields()
        setModal(true)
    }

    async function onOk() {
        setModalResults(null)
        setModalDescription(t("ConnectDevices.TestDNS.Description.Loading"))
        const values = form.getFieldsValue()

        const data = {
            id: modalDevice,
            query: values.query
        }

        const raw = await kernel.api.post(`/dohzel/connect/dns?profileId=${id}`, data)
        if (raw.error || !raw.data) {
            if (!raw.error && !raw.error)
                setModalDescription(t("ConnectDevices.TestDNS.Description.Error") + ": No data")
            else
                setModalDescription(t("ConnectDevices.TestDNS.Description.Error") + ": " + raw.error)
            return
        }

        setTimeout(() => {
            const results = [...raw.data]
            if (results.length === 0)
                return (setModalDescription(t("ConnectDevices.TestDNS.Description.Error") + ": No record"))
            setModalResults(results)
            setModalDescription(null)
        }, 500)
    }

    async function onCancel() {
        setModal(false)
    }

    return (<>

        <Modal
            title={t("ConnectDevices.TestDNS.Title")}
            open={isModal}
            onOk={onOk}
            onCancel={onCancel}
            okText={t("ConnectDevices.TestDNS.Tester")}
            cancelText={t("ConnectDevices.TestDNS.Close")}
            // okButtonProps={{disabled: true}}
            forceRender
        >
            <Alert
                type="info" showIcon
                description={t("ConnectDevices.TestDNS.info")}
                closable
            />
            <p style={{ paddingTop: 10 }}>
                <Form
                    form={form}
                >
                    <Form.Item label={t("ConnectDevices.TestDNS.Domain")} name="query">
                        <Input />
                    </Form.Item>
                </Form>
            </p>

            {modalResults ?
                <p style={{ paddingTop: 10 }}>
                    <Row>
                        {modalResults.map((item) => {
                            return (<Col span={12}><Tag>{item.type}</Tag> {item.data}</Col>)
                        })}
                    </Row>
                </p>
                : null}

            {modalDescription ? <Empty description={modalDescription} /> : null}
        </Modal>

        <Table dataSource={devices} columns={columns} />
    </>)
}

/*
 *
 * Connect
 */
export function Connect({ kernel }) {
    const { t } = useTranslation();

    return (
        <>
            <DiventryBlock >
                <Alert
                    type="info" showIcon
                    description={t("Connect.infoDescription")}
                    closable
                />
                <Tabs defaultActiveKey="1" items={[
                    {
                        key: 'rules',
                        label: t("Connect.rulesTab"),
                        children: <ConnectRules kernel={kernel} />,
                    },
                    {
                        key: 'devices',
                        label: t("Connect.devicesTab"),
                        children: <ConnectDevices kernel={kernel} />,
                    }
                ]} />
            </DiventryBlock>
        </>
    );
}
