import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { DiventryMenu } from '../../Templates/Menu';
import {
    DiventryLayout,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventrySide
} from '../../Templates/Layout';
import { Information } from './Information';
import { Emails } from './Emails';
import { Billing } from './Billing';
import { PaiementMethod } from './PaiementMethod';
import { Share } from './Share';
import { API } from './API';
import { Invoices } from './Invoices';
import { Subscriptions } from './Subscriptions';

export function Account({ kernel }) {
    const { t } = useTranslation();
    let params = useParams();

    const [key, setKey] = useState(null);
    const [page, setPage] = useState(null);

    useEffect(() => {
        const page = pages[params.visionPage];
        setKey(params.visionPage);
        setPage(page);
    }, [params.visionPage]);

    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(true);
    const [activeKey, setActiveKey] = useState(null);
    const [width, setWidth] = useState();
    const [error, setError] = useState(null);
    const [data, setData] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        var interval = null;

        const loader = async () => {
            var response = await kernel.api.get("/user/me", 1000 * 60);
            if (response.error) {
                notification.error({
                    message: t('Account.errorFetchingInfo'),
                    description: <>{response.error}</>,
                    placement: "topRight",
                });
                interval = setTimeout(loader, 10000);
                return;
            }
            setData(response.data);
            setLoading(false);
        };
        loader();

        return () => {
            if (interval) clearTimeout(interval);
        };

    }, [loaded]);

    const onUpdate = async (form) => {
        if (!form) return (setLoaded(!loaded));
        var response = await kernel.api.post("/user/update", form);
        if (response.error) {
            notification.error({
                message: t('Account.errorUpdatingAccount'),
                description: <>{response.error}</>,
                placement: "topRight",
            });

            return;
        }

        notification.success({
            message: t('Account.accountUpdated'),
            description: t('Account.infoUpdated'),
            placement: "topRight",
        });
        setLoaded(!loaded);
    };

    const pages = {
        'information': {
            key: 'information',
            title: t('Account.accountInfo'),
            description: t('Account.personalInfo'),
            ref: Information
        },
        'emails': {
            key: 'emails',
            title: t('Account.emails'),
            description: t('Account.emailsDescription'),
            ref: Emails
        },
        'billing': {
            key: 'billing',
            title: t('Account.billingInfo'),
            description: t('Account.billingDescription'),
            ref: Billing
        },
        'payment-method': {
            key: 'payment-method',
            title: t('Account.paymentMethod'),
            description: t('Account.paymentMethodDescription'),
            ref: PaiementMethod
        },
        'invoices': {
            key: 'invoices',
            title: t('Account.invoices'),
            description: t('Account.invoicesDescription'),
            ref: Invoices
        },
        'subscriptions': {
            key: 'subscriptions',
            title: t('Account.subscriptions'),
            description: t('Account.subscriptionsDescription'),
            ref: Subscriptions
        },
        'share': {
            key: 'share',
            title: t('Account.shareCode'),
            description: t('Account.shareCodeDescription'),
            ref: Share
        },
        'api': {
            key: 'api',
            title: t('Account.apiKeys'),
            description: t('Account.apiKeysDescription'),
            ref: API
        }
    };

    const Switch = page?.ref;
    return (
        <DiventryLayout>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title={page?.title}
                description={page?.description}
            />
            <DiventryLayoutContent>

                {Switch ?
                    <DiventrySide
                        selectedKeys={[params.visionPage]}
                        menu={[
                            {
                                label: t('Account.accountInfo'),
                                key: 'information',
                            },
                            {
                                label: t('Account.emails'),
                                key: 'emails',
                            },
                            {
                                label: t('Account.billingInfo'),
                                key: 'billing',
                            },
                            {
                                label: t('Account.paymentMethod'),
                                key: 'payment-method',
                            },
                            {
                                label: t('Account.subscriptions'),
                                key: 'subscriptions',
                            },
                            {
                                label: t('Account.invoices'),
                                key: 'invoices',
                            },
                            {
                                label: t('Account.shareCode'),
                                key: 'share',
                            },
                            {
                                label: t('Account.apiKeys'),
                                key: 'api',
                            },
                            { type: 'divider' },
                            {
                                label: t('Account.logout'),
                                key: 'disconnect',
                                danger: true
                            },
                        ]}
                        onClick={(e) => {
                            if (e.key === "disconnect") {
                                notification.success({
                                    message: t('Account.disconnected'),
                                    description: t('Account.sessionClosed'),
                                });
                                kernel.session.store("");
                                navigate(`/session/login`);
                                return;
                            }

                            navigate(`/account/${e.key}`);
                        }}
                    >
                        <Switch onUpdate={onUpdate} values={data} kernel={kernel} />
                    </DiventrySide>
                    : null}
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />

        </DiventryLayout>
    );
}
