import { useEffect, useState } from "react";
import {
    Button,
    notification
} from 'antd';

import { DiventryBlock } from '../../Templates/Layout';
import { useTranslation } from 'react-i18next';

export function Share({ onUpdate, values, kernel }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const generate = async (form) => {
        setLoading(true);
        var response = await kernel.api.get("/user/shareCode");
        if (!response || response.error) {
            notification.error({
                message: t('Share.errorGeneratingCode'),
                description: response?.error || null
            });
            setLoading(false);
            return;
        }

        notification.success({
            message: t('Share.codeGenerated'),
            description: t('Share.newCodeGenerated')
        });
        values.shareCode = response.data.shareCode;
        onUpdate(values);
        setLoading(false);
    };

    return (
        <DiventryBlock>
            <div style={{
                padding: 20,
                width: "100%",
                fontSize: 50,
                fontWeight: 800
            }}>
                <center>
                    {values.shareCode ?
                        <>
                            {values.shareCode}<br />
                            <Button type="primary" loading={loading} onClick={generate}>{t('Share.regenerate')}</Button>
                        </>
                        :
                        <>
                            <Button type="primary" loading={loading} onClick={generate}>{t('Share.generate')}</Button>
                        </>}
                    <p style={{
                        fontSize: 12,
                        padding: 20,
                        fontWeight: 600
                    }}>
                        {t('Share.description')}
                    </p>
                </center>
            </div>
        </DiventryBlock>
    );
}
