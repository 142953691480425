import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
    Button,
    Table,
    Space,
    notification,
    Tag
} from 'antd'

import { DiventryBlock } from '../../Templates/Layout';
import { useTranslation } from 'react-i18next';

export function Invoices({ onUpdate, values, kernel }) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(true);
    const [list, setList] = useState([]);
    const [balance, setBalance] = useState(0);

    const columns = [
        {
            title: t('Invoices.reference'),
            dataIndex: 'number',
            key: 'number',
            render: (text, record, index) => {
                return (<Link to={record.viewURL} target="_blank">{text}</Link>);
            }
        },
        {
            title: t('Invoices.date'),
            dataIndex: 'effectiveAt',
            key: 'effectiveAt',
            sorter: (a, b) => a.effectiveAt - b.effectiveAt,
            render: (text, record, index) => {
                return (<div>{new Date(text).toLocaleString()}</div>);
            }
        },
        {
            title: t('Invoices.amount'),
            dataIndex: 'amountDue',
            key: 'amountDue',
            sorter: (a, b) => a.amountDue - b.amountDue,
            render: (text, record, index) => {
                return (<div>{record.currency.toUpperCase()} {text.toFixed(2)}</div>);
            }
        },
        {
            title: t('Invoices.paid'),
            dataIndex: 'amountPaid',
            key: 'amountPaid',
            sorter: (a, b) => a.amountPaid - b.amountPaid,
            render: (text, record, index) => {
                return (<div>{record.currency.toUpperCase()} {text.toFixed(2)}</div>);
            }
        },
        {
            title: t('Invoices.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => {
                const statusText = t(`Invoices.${text}Status`);
                let color;
                switch (text) {
                    case "paid":
                        color = "blue";
                        break;
                    case "draft":
                        color = "grey";
                        break;
                    case "open":
                        color = "green";
                        break;
                    case "uncollectible":
                        color = "orange";
                        break;
                    case "void":
                        color = "purple";
                        break;
                    default:
                        color = "gray";
                        break;
                }
                return (
                    <Tag color={color} style={{ fontWeight: 700 }}>
                        {statusText}
                    </Tag>
                );
            }
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            align: "right",
        },
    ];

    useEffect(() => {
        async function fetchList() {
            const response = await kernel.api.get("/user/invoices/list");
            if (!response || response.error) {
                notification.error({
                    message: t('Invoices.errorFetchingInvoices'),
                    description: response?.error || null
                });
                return;
            }
            const balance = calculateBalance(response.data);
            setBalance(balance);

            for (let item of response.data) {
                item.actions =
                    <Space.Compact size="small">
                        {item.status === "open" ?
                            <Button type="primary" onClick={() => {
                                window.open(item.viewURL);
                            }}>{t('Invoices.payNow')}</Button>
                            :
                            <Button onClick={() => {
                                window.open(item.downloadURL);
                            }}>{t('Invoices.download')}</Button>
                        }
                    </Space.Compact>;
            }
            setList(response.data);
        }

        fetchList();
    }, [loaded]);

    const calculateBalance = (invoices) => {
        let totalAmount = 0;
        let paidAmount = 0;

        invoices.forEach(invoice => {
            if (invoice.status === "active" || invoice.status === "trialing" || invoice.status === "open") {
                totalAmount += invoice?.amountDue;
            }

            if (invoice.status === "paid") {
                totalAmount += invoice?.amountDue;
                paidAmount += invoice?.amountPaid;
            }
        });

        const balance = totalAmount - paidAmount;
        return balance;
    };

    return (
        <DiventryBlock>
            <Table
                size="small"
                dataSource={list}
                columns={columns}
                tableLayout="fixed"
                footer={() => (
                    <h3>{t('Invoices.balance')}: CHF {balance.toFixed(2)}</h3>
                )}
            />
        </DiventryBlock>
    );
}
