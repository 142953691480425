import { useEffect, useState, useRef } from "react"
import { useParams, useLocation, useNavigate, Link } from "react-router-dom"
import { useTranslation } from "react-i18next" // Importation de i18next

import {
    Tabs,
    Space,
    Button,
    Dropdown,
    Form,
    Modal,
    Input,
    notification
} from 'antd';

import {
    CaretDownOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutDohzel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventrySide
} from '../../../Templates/Layout'

import Logo from '../../../Images/logoSmall.png'

import Brands from '../Brands/Index'

import { Settings } from './Settings'
import { Statistics } from './Statistics'
import { Logs } from './Logs'
// import { Devices } from './Devices'
// import { Statistics } from './Statistics'

export function Null() {
    return (<>vcfevsrvf</>)
}

export function Device({ kernel }) {
    const { t } = useTranslation(); // Initialiser la fonction t pour la traduction

    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    var ignore = false
    const [back, setBack] = useState(null)
    const [loaded, setLoaded] = useState(true)
    const [tabs, setTabs] = useState([])

    const [profile, setProfile] = useState({})
    const [device, setDevice] = useState({})

    const [modalCreate, setModalCreate] = useState(false)
    const [theForm] = Form.useForm()

    const pages = {
        'statistics': {
            key: 'statistics',
            title: t('Device.statisticsTitle'), // Traduction via i18n
            description: t('Device.statisticsDescription'), // Traduction via i18n
            ref: Statistics
        },
        'logs': {
            key: 'logs',
            title: t('Device.logsTitle'), // Traduction via i18n
            description: t('Device.logsDescription'), // Traduction via i18n
            ref: Logs
        },
        'settings': {
            key: 'settings',
            disabled: kernel.subscription.expiration,
            title: t('Device.settingsTitle'), // Traduction via i18n
            description: t('Device.settingsDescription'), // Traduction via i18n
            ref: Settings
        },
        // 'installation': {
        //     key: 'installation',
        //     title: t('Device.installationTitle'), // Traduction via i18n
        //     description: t('Device.installationDescription'), // Traduction via i18n
        //     ref: Null
        // },
    }    

    useEffect(() => {
        if (ignore)
            return
        setBack(localStorage.getItem("deviceBack"))

        async function fetch() {
            var response = await kernel.api.get(`/dohzel/device/get?id=${params.id}`)
            if (response.error) {
                notification.error({
                    message: t('Device.errorFetchingInfo'), // Utilisation de la traduction
                    description: response.error
                })

                return
            }

            setDevice(response.data)

            response = await kernel.api.get(`/dohzel/profile/get?id=${response.data.profileId}`)
            if (response.error) {
                notification.error({
                    message: t('Device.errorFetchingInfo'), // Utilisation de la traduction
                    description: response.error
                })

                return
            }
            setProfile(response.data)

            setTabs(Object.values(pages))
        }

        fetch()

        return (() => {
            ignore = true
        })
    }, [params, loaded])

    async function updateDevice() {
        setLoaded(!loaded)
    }

    return (
        <DiventryLayoutDohzel>

            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                noStyle={true}
                title={<Brands.BrandedDevice device={device} font={24} size={60} />}
                description={`${t('Device.dohzelDevice')} ${profile.name}`} // Traduction
            />
            <DiventryLayoutContent>
                {back ?
                    <DiventryBlock>
                        <Button onClick={() => navigate(back)}>{t('Device.returnToProfile')}</Button> {/* Traduction */}
                    </DiventryBlock>
                    : null}
                <DiventryBlock>
                    <Tabs
                        type="card"
                        activeKey={params.page1}
                        onChange={(tab) => {
                            navigate(`/dohzel/device/${params.id}/${tab}`)
                        }}
                        items={tabs.map((item) => {
                            return ({
                                key: item.key,
                                label: t(`Device.${item.key}`), // Traduction des titres des tabs
                                disabled: item.disabled,
                                children: <div style={{ paddingTop: 10 }}>
                                    <item.ref kernel={kernel} device={device} updateDevice={updateDevice} />
                                </div>
                            })
                        })}
                    />
                </DiventryBlock>

            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />

        </DiventryLayoutDohzel>
    )
}
