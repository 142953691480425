import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Space,
    Popconfirm,
    Form,
    message
} from 'antd'

import { DiventryBlock } from '../../../../Templates/Layout'
import { useTranslation } from "react-i18next";

export function Advanced({ kernel, updateDevice }) {

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();

    const confirm = async (e) => {
        const deletedDevice = await kernel.api.post(`/dohzel/profile/remove`, {
            id
        });
        if (deletedDevice.error) {
            message.error(t('Advanced.deleteProfileError'));
            return;
        } 
        message.success(t('Advanced.deleteProfileSuccess'));
        navigate("/dohzel/profile");
    };

    return (
        <>
            <DiventryBlock>
                <Space.Compact>
                    <Popconfirm
                        title={t('Advanced.deleteProfile')}
                        description={t('Advanced.deleteProfileDescription')}
                        onConfirm={confirm}
                        okText={t('YesConfirmation')}
                        cancelText={t('NoConfirmation')}
                    >
                        <Button danger type="primary">{t('Advanced.deleteProfile')}</Button>
                    </Popconfirm>
                </Space.Compact>
            </DiventryBlock>
        </>
    );
}
