import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Table,
    Space,
    notification,
    Tag
} from 'antd';
import { DiventryBlock } from '../../Templates/Layout';
import { useTranslation } from 'react-i18next';

export function SubscriptionsItems({ kernel, priceId }) {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(true);
    const [list, setList] = useState([]);

    const columns = [
        {
            title: t('SubscriptionsItems.productName'),
            dataIndex: 'productName',
            key: 'productName',
            render: (text) => (
                <div>{text}</div>
            )
        },
        {
            title: t('SubscriptionsItems.licenseUsed'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => (
                <div>{record?.deviceCount ?? 0} / {text} {record?.deviceCount === 1 ? t('SubscriptionsItems.licence') : t('SubscriptionsItems.licences')}</div>
            )
        },
        {
            title: t('SubscriptionsItems.startDate'),
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => a.startDate - b.startDate,
            render: (text) => (
                <div>{new Date(text).toLocaleString()}</div>
            )
        },
        {
            title: t('SubscriptionsItems.nextBillingDate'),
            dataIndex: 'currentPeriodEnd',
            key: 'currentPeriodEnd',
            sorter: (a, b) => a.currentPeriodEnd - b.currentPeriodEnd,
            render: (text) => (
                <div>{new Date(text).toLocaleString()}</div>
            )
        },
        {
            title: t('SubscriptionsItems.amount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                <div>{record.currency.toUpperCase()} {text.toFixed(2) * (record?.quantity ?? 1)}</div>
            )
        },
        {
            title: t('SubscriptionsItems.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                let color = text === "active" ? "green" : text === "canceled" ? "red" : "orange";
                return (
                    <Tag color={color} style={{ fontWeight: 700 }}>
                        {text.charAt(0).toUpperCase() + text.slice(1)}
                    </Tag>
                );
            }
        },
    ];    

    useEffect(() => {
        async function fetchList() {
            const response = await kernel.api.get(`/user/subscriptions/list`);
            if (!response || response.error) {
                notification.error({
                    message: t('SubscriptionsItems.errorFetchingSubscriptions'),
                    description: response?.error || null
                });
                return;
            }

            const subscriptions = response.data;

            setList(subscriptions);
        }

        fetchList();
    }, [loaded]);

    const handleManage = async () => {
        try {
            const response = await kernel.api.get(`/user/subscriptions/manage`);
            if (response.error) {
                notification.error({
                    message: t('SubscriptionsItems.error'),
                    description: response.error,
                });
                return;
            }
    
            window.location.href = response.data.url;
        } catch (error) {
            notification.error({
                message: t('SubscriptionsItems.error'),
                description: error.message,
            });
        }
    };

    return (
        <DiventryBlock>
            <Table
                size="small"
                dataSource={list}
                columns={columns}
                tableLayout="fixed"
                footer={(
                    () => (
                        list.length &&
                        <Space size="small" style={{marginTop: 20}}>
                            <Button type="primary" onClick={() => handleManage()}>
                                {t('SubscriptionsItems.manageSubscriptions')}
                            </Button>
                        </Space>
                    )
                )}
            />
        </DiventryBlock>
    );
}
