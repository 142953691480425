import { useEffect, useState } from "react"

import {
    Button,
    Input,
    Table,
    Select,
    notification,
    Modal,
    Tag,
    Popconfirm,
    Tooltip,
    Form
} from 'antd'

import { CheckCircleTwoTone, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import {
    DiventryBlock
} from '../../Templates/Layout'

import { useTranslation } from 'react-i18next';

export function API({ onUpdate, values, kernel }) {
    const { t } = useTranslation(); // Utilisation du hook i18n
    const [loaded, setLoaded] = useState(true);

    const [permissionsList, setPermissionsList] = useState({});
    const [list, setList] = useState([]);

    const [isModalCreate, setIsModalCreate] = useState(false);
    const [createForm] = Form.useForm();

    const columns = [
        {
            title: t('API.nameColumn'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => (<strong>{text}</strong>)
        },
        {
            title: t('API.createdAtColumn'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: (text) => (
                <div style={{ fontSize: 10 }}>{new Date(text).toLocaleString()}</div>
            )
        },
        {
            title: t('API.lastAccessColumn'),
            dataIndex: 'lastAccess',
            key: 'lastAccess',
            sorter: (a, b) => a.createdAt - b.createdAt,
            render: (text) => (
                <div style={{ fontSize: 10 }}>{text ? new Date(text).toLocaleString() : '-'}</div>
            )
        },
        {
            title: t('API.permissionsColumn'),
            dataIndex: 'permissionsTag',
            key: 'permissions',
        },
        {
            title: t('API.apiKeyColumn'),
            dataIndex: 'apiKey',
            key: 'apiKey',
        },
        {
            dataIndex: 'actions',
            key: 'actions',
            align: 'right',
        },
    ];

    useEffect(() => {
        async function fetchPermissions() {
            const response = await kernel.api.get("/user/api/permissions");
            if (!response || response.error) {
                notification.error({
                    message: t('API.permissionsFetchError'),
                    description: response?.error || null
                });
                return;
            }
            setPermissionsList(response.data);
            fetchList(response.data);
        }

        async function fetchList(permissions) {
            const response = await kernel.api.get("/user/api/list");
            if (!response || response.error) {
                notification.error({
                    message: t('API.listFetchError'),
                    description: response?.error || null
                });
                return;
            }
            for (let item of response.data) {
                item.permissionsTag = item.permissions.map((perm) => (
                    <Tag>{permissions[perm]}</Tag>
                ));
                item.actions = (
                    <Popconfirm
                        placement="left"
                        title={t('API.popconfirmTitle')}
                        okText={t('API.popconfirmOk')}
                        cancelText={t('API.popconfirmCancel')}
                        onConfirm={async () => {
                            const ret = await kernel.api.post(`/user/api/remove`, { id: item.id });
                            if (ret.error) {
                                notification.error({
                                    message: t('API.deleteError'),
                                    description: ret.error
                                });
                                return;
                            }
                            notification.success({
                                message: t('API.deleteSuccess')
                            });
                            setLoaded(!loaded);
                        }}
                    >
                        <Button>{t('API.deleteButton')}</Button>
                    </Popconfirm>
                );
            }
            setList(response.data);
        }

        fetchPermissions();
    }, [loaded, kernel, t]);

    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue()
        };
        const ret = await kernel.api.post(`/user/api/create`, data);
        if (ret.error) {
            notification.error({
                message: t('API.createError'),
                description: ret.fields
                    ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>)
                    : ret.error
            });
            return;
        }
        notification.success({
            message: t('API.createSuccess')
        });
        setIsModalCreate(false);
        setLoaded(!loaded);
    };

    const handleCreateCancel = () => {
        setIsModalCreate(false);
    };

    return (
        <>
            <Modal
                title={t('API.modalTitle')}
                open={isModalCreate}
                onOk={handleCreateOk}
                onCancel={handleCreateCancel}
            >
                <Form layout="vertical" form={createForm}>
                    <Form.Item label={t('API.formNameLabel')} name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t('API.formPermissionsLabel')} name="permissions">
                        <Select
                            mode="multiple"
                            placeholder={t('API.formPermissionsPlaceholder')}
                            defaultValue={[]}
                            options={Object.keys(permissionsList).map((key) => ({
                                value: key,
                                label: permissionsList[key]
                            }))}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <DiventryBlock bottom={true}>
                <Button
                    onClick={() => {
                        createForm.resetFields();
                        setIsModalCreate(true);
                    }}
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    {t('API.createButton')}
                </Button>
            </DiventryBlock>

            <DiventryBlock>
                <Table size="small" dataSource={list} columns={columns} pagination={false} />
            </DiventryBlock>
        </>
    );
}
