import { useEffect, useState } from "react"
import {
    Button,
    Input,
    Table,
    Alert,
    notification,
    Space,
    Popconfirm,
    Tooltip
} from 'antd'
import { CheckCircleTwoTone, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { DiventryBlock } from '../../Templates/Layout'
import { useTranslation } from 'react-i18next'

export function Emails({ onUpdate, values, kernel }) {
    const { t } = useTranslation();

    const [dataSource, setDataSource] = useState([])
    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)

    useEffect(() => {
        if (!values.emails) return;

        const newDatasource = []
        for (let email of values.emails) {
            const Delete = () =>
                <Tooltip title={t('Emails.deleteEmail')}>
                    <Popconfirm
                        placement="left"
                        title={<>{t('Emails.deleteConfirmation', { email: email.email })}</>}
                        description={t('Emails.deleteWarning')}
                        onConfirm={async () => {
                            var response = await kernel.api.post("/user/email/delete", { id: email.id })
                            if (response.error) {
                                notification.error({
                                    message: t('Emails.emailError'),
                                    description: <>{response.error}</>,
                                    placement: "topRight",
                                })
                                setConfirmLoading(false)
                                return
                            }

                            notification.success({
                                message: t('Emails.email'),
                                description: t('Emails.emailRemoved'),
                                placement: "topRight",
                            })

                            onUpdate()
                        }}
                        okText={t('Emails.yes')}
                        cancelText={t('Emails.no')}
                    >
                        <Button
                            shape="circle"
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Tooltip>

            newDatasource.push({
                key: email.id,
                email: email.email,
                status: <Space>
                    {email.error ? 
                        <Alert type="error" size="small" message={email.error} /> : null}

                    {email.activated !== true ? <>
                        {t('Emails.pendingRequest')}
                        <Button size="small" onClick={async () => {
                            var response = await kernel.api.post("/user/email/resent", { id: email.id })
                            if (response.error) {
                                notification.error({
                                    message: t('Emails.emailError'),
                                    description: <>{response.error}</>,
                                    placement: "topRight",
                                })
                                setConfirmLoading(false)
                                return
                            }

                            notification.success({
                                message: t('Emails.email'),
                                description: t('Emails.activationResent'),
                                placement: "topRight",
                            })

                        }}>{t('Emails.resend')}</Button>
                        <Delete />
                    </> : null}

                    {email.activated === true ? <>
                        {t('Emails.verified')}
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        <Delete />
                    </> : null}
                </Space>,
            })
        }
        setDataSource(newDatasource)
    }, [values]);

    const columns = [
        {
            title: t('Emails.email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            dataIndex: 'status',
            key: 'status',
            width: '50%',
            align: "right",
        },
    ]

    const showPopconfirm = () => {
        setOpen(true)
    }

    const handleOk = async () => {
        setConfirmLoading(true)
        var response = await kernel.api.post("/user/email/add", { email })
        if (response.error) {
            notification.error({
                message: t('Emails.errorAddingEmail'),
                description: <>{response.error}</>,
                placement: "topRight",
            })
            setConfirmLoading(false)
            return
        }

        notification.success({
            message: t('Emails.email'),
            description: t('Emails.emailAdded'),
            placement: "topRight",
        })

        onUpdate()
        setTimeout(() => {
            setOpen(false)
            setConfirmLoading(false)
        }, 1000)
    };

    const handleCancel = () => {
        setOpen(false)
    };

    return (
        <>
            <DiventryBlock bottom={true}>
                <Popconfirm
                    open={open}
                    okButtonProps={{ loading: confirmLoading }}
                    placement="right"
                    title={t('Emails.linkNewEmail')}
                    description={<>
                        <Input onChange={({ target }) => setEmail(target.value)} />
                    </>}
                    icon={<PlusOutlined color="black" />}
                    onConfirm={handleOk}
                    onCancel={handleCancel}
                    okText={t('Emails.add')}
                    cancelText={t('Emails.cancel')}
                >
                    <Button onClick={showPopconfirm} type="primary" icon={<PlusOutlined />}>
                        {t('Emails.addEmail')}
                    </Button>
                </Popconfirm>
            </DiventryBlock>
            <DiventryBlock>
                <Table dataSource={dataSource} columns={columns} pagination={false} />
            </DiventryBlock>
        </>
    )
}
