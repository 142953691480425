import React, { useState } from 'react';
import { Form, Input, Button, Modal, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DiventryBlock } from './Layout';
import { UtilsInputLang } from './Utils';

const PasswordReset = ({ kernel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const validatePassword = (_, value) => {
    if (!value || value.length < 8) {
      return Promise.reject(new Error(t('PasswordReset.passwordLengthError')));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('PasswordReset.passwordMismatchError')));
    }
  });

  const onFinish = async (form) => {
    setLoading(true);
    var response = await kernel.api.post("/user/update", form);
    if (response.error) {
      notification.error({
        message: t('PasswordReset.errorTitle'),
        description: <>{response.error}</>,
        placement: "topRight",
      });
      return;
    }
    setLoading(false);
    handleCancel();
    notification.success({
      message: t('PasswordReset.successTitle'),
      description: t('PasswordReset.successMessage'),
      placement: "topRight",
    });
  };

  const handleCancel = () => {
    setIsModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModal(true)}>
        {t('PasswordReset.changePasswordButton')}
      </Button>
      <Modal
        title={t('PasswordReset.modalTitle')}
        open={isModal}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="oldPassword"
            label={t('PasswordReset.oldPasswordLabel')}
            rules={[
              { required: true, message: t('PasswordReset.oldPasswordRequired') },
              { validator: validatePassword }
            ]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label={t('PasswordReset.newPasswordLabel')}
            rules={[
              { required: true, message: t('PasswordReset.newPasswordRequired') },
              { validator: validatePassword }
            ]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label={t('PasswordReset.confirmPasswordLabel')}
            dependencies={['newPassword']}
            rules={[
              { required: true, message: t('PasswordReset.confirmPasswordRequired') },
              validateConfirmPassword
            ]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>

          <Form.Item>
            <Button onClick={() => setIsModal(false)} loading={loading}>
              {t('PasswordReset.cancelButton')}
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              {t('PasswordReset.changePasswordButton')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PasswordReset;
